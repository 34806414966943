import React from "react";

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className="promo-section ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-9">
                <div className="section-heading mb-5">
                  <h2>Perguntas Frequentes</h2>
                  <p className="lead">
                    
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div id="accordion-1" className="accordion accordion-faq">
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-1"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-1"
                      aria-expanded="false"
                      aria-controls="collapse-1-1"
                    >
                      <h6 className="mb-0">
                        <span className="ti-receipt mr-3"></span> 
                      </h6>
                    </div>
                    <div id="collapse-1-1" className="collapse" aria-labelledby="heading-1-1" data-parent="#accordion-1">
                      <div className="card-body">
                        <p>
                          
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-2"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-2"
                      aria-expanded="false"
                      aria-controls="collapse-1-2"
                    >
                      <h6 className="mb-0">
                        <span className="ti-gallery mr-3"></span> 
                      </h6>
                    </div>
                    <div id="collapse-1-2" className="collapse" aria-labelledby="heading-1-2" data-parent="#accordion-1">
                      <div className="card-body">
                        <p>
                      
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-3"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-3"
                      aria-expanded="false"
                      aria-controls="collapse-1-3"
                    >
                      <h6 className="mb-0">
                        <span className="ti-wallet mr-3"></span> 
                      </h6>
                    </div>
                    <div id="collapse-1-3" className="collapse" aria-labelledby="heading-1-3" data-parent="#accordion-1">
                      <div className="card-body">
                        <p>
                          
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div id="accordion-2" className="accordion accordion-faq">
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-2-1"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-2-1"
                      aria-expanded="false"
                      aria-controls="collapse-2-1"
                    >
                      <h6 className="mb-0">
                        <span className="ti-receipt mr-3"></span> 
                      </h6>
                    </div>
                    <div id="collapse-2-1" className="collapse" aria-labelledby="heading-2-1" data-parent="#accordion-2">
                      <div className="card-body">
                        <p>
                          
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-2-2"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-2-2"
                      aria-expanded="false"
                      aria-controls="collapse-2-2"
                    >
                      <h6 className="mb-0">
                        <span className="ti-lock mr-3"></span> 
                      </h6>
                    </div>
                    <div id="collapse-2-2" className="collapse" aria-labelledby="heading-2-2" data-parent="#accordion-2">
                      <div className="card-body">
                        <p>
                        
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-2-3"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-2-3"
                      aria-expanded="false"
                      aria-controls="collapse-2-3"
                    >
                      <h6 className="mb-0">
                        <span className="ti-widget mr-3"></span> 
                      </h6>
                    </div>
                    <div id="collapse-2-3" className="collapse" aria-labelledby="heading-2-3" data-parent="#accordion-2">
                      <div className="card-body">
                        <p>
                          
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="gray-light-bg ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading mb-5 text-center">
                  <h2>Perguntas Frequentes</h2>
                  <p className="lead">
                    
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="single-faq">
                  <h5></h5>
                  <p>
                    
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-faq">
                  <h5></h5>
                  <p>
                    
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-faq">
                  <h5></h5>
                  <p>
                    
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-faq">
                  <h5></h5>
                  <p>
                    
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-faq">
                  <h5></h5>
                  <p>
                    
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-faq">
                  <h5></h5>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Faq;
