import React from "react";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import AgencyCoApp from "./reducers";
import Routes from "./routers";
import { FloatingWhatsApp } from "react-floating-whatsapp-button";
import "react-floating-whatsapp-button/dist/index.css";
// create store
const store = createStore(
  AgencyCoApp,
  composeWithDevTools()
  // applyMiddleware(...middleware),
  // other store enhancers if any
);

function App() {
  return (
    <Provider store={store}>
      <Routes />
      <link rel="stylesheet" href="floating-wpp.min.css"></link>
        <script src="//code.jquery.com/jquery-latest.min.js"></script>  
        <script type="text/javascript" src="floating-wpp.min.js"></script> 
        <div class="floating-wpp">
                  <FloatingWhatsApp
                    phone="5541999421860"
                    message="Olá, eu gostaria de informações sobre a Seguidor4u"
                    popupMessage="Posso lhe ajudar?"
                    //autoOpenTimeout="9000"
                    //showPopup false
                    //showOnIE false
                    //autoOpenTimeout="2000"
                    headerTitle="@seguidor4u"
                    //accountName="Foo"
                    //allowClickAway
                    //notification
                    //notificationDelay={60000} // 1 minute
                    //notificationSound
                  />
                </div>      
    </Provider>
  );
}

export default App;
