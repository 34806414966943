import React from "react";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <div id="features" className={"feature-section ptb-100 " + (this.props.bgColor && this.props.bgColor === 'gray' ? 'gray-light-bg' : '')}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <span className="badge badge-primary badge-pill">
                    Melhores Marcas
                  </span>
                  <h2>
                  Veja as Mídias com que Trabalhamos <br />
                    <span>e o que conseguimos fazer por você</span>
                  </h2>
                  <p>
                    Descubra como a Seguidor4u pode ajudar a você ter o seu engajamento real e de forma inteligente.
                  </p>
                </div>
              </div>
            </div>

            <div className="row row-grid align-items-center">
              <div className="col-lg-4">
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-1 rounded-circle">
                      <span className="ti-instagram"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Instagram</h5>
                    <p className="mb-0">
                    Quanto mais se expuser nas redes sociais, mais fácil será para as pessoas saberem quem você é. O Seguidor4u tem o que você precisa para ganhar engajamento no seu Instagram.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-2 rounded-circle">
                      <span className="ti-facebook"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Facebook</h5>
                    <p className="mb-0">
                    É verdade que o Facebook é uma ótima maneira de promover seus produtos, serviços e aumentar seu público. Temos os seguidores que você precisa para bombar.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-3 rounded-circle">
                      <span className="ti-twitter"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Twitter</h5>
                    <p className="mb-0">
                    Descubra como nossos seguidores do Twitter podem lhe auxiliar a ter mais engajamento na sua conta.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="position-relative" style={{zIndex: 10}}>
                  <img
                    alt="placeholder"
                    src="img/image-10.png"
                    className="img-center img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-4 rounded-circle">
                      <span className="ti-youtube"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Youtube</h5>
                    <p className="mb-0">
                      Precisando de seguidores ou views nos seus vídeos do Youtube, nós temos pacotes de visualizações que irão fazer o seu canal rentabilizar.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-5 rounded-circle">
                      <span className="ti-github"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>TikTok</h5>
                    <p className="mb-0">
                      Você faz vídeos interessantes no TikTok mas não tem seguidores o suficiente para bombar, nós podemos lhe auxiliar.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-6 rounded-circle">
                      <span className="ti-linkedin"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Linkedin</h5>
                    <p className="mb-0">
                      Precisando de comentários e avaliações no Likedin, nós podemos lhe auxiliar, veja nossos pacotes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Feature;
