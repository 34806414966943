import React from "react";
import { connect } from "react-redux";

class PromoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className={"promo-section ptb-100 " + (this.props.removeTopMargin ? '' : 'mt-5')}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-10">
                <div className="section-heading mb-5">
                  <a className="btn btn-yellow" href="https://api.whatsapp.com/send?phone=5541999421860&text=Olá, eu gostaria de aumentar a minha visibilidade nas Redes através da Seguidor4U!!!">QUERO AUMENTAR A MINHA VISIBILIDADE JÁ</a>                    
                  <h5 className="h5 mt-3 mb-6">
                    As suas publicações orgânicas e anúncios direcionados não estão atingindo o público que você almeja, veja o que podemos fazer por você:
                  </h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-6 mb-lg-0">
                <div className="card single-promo-card single-promo-hover">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="ti-credit-card icon-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>AUTORIDADE</h5>
                      <p className="text-muted mb-0">
                      O que vão dizer de uma postagem com mais de 1.000 curtidas? Com certeza não terão como criticar e negar que está boa.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="card single-promo-card single-promo-hover mb-lg-0">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="ti-control-record icon-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>PROVA SOCIAL</h5>
                      <p className="text-muted mb-0">
                      Quanto mais seguidores, curtidas e comentários, mais prova social terá seu post. Me diz ai, quem tem mais chances de vender, um perfil com muita prova social ou um zerado?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="card single-promo-card single-promo-hover mb-lg-0">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="ti-vector icon-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>RECURSOS</h5>
                      <p className="text-muted mb-0">
                      Você precisa de no mínimo 10 mil seguidores para liberar o seu ARRASTA PRA CIMA e nós te entregamos com velocidade e eficiência para você não se preocupar com nada.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="card single-promo-card single-promo-hover mb-lg-0">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="ti-receipt icon-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>RECONHECIMENTO</h5>
                      <p className="text-muted mb-0">
                      Você já sentiu que nos dias de hoje rola um julgamento nas redes sociais para saber se a pessoa é real? Aumente seus números e veja o resultado que vai te trazer!.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(PromoSection);
