import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="about" className="about-us ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <span className="badge badge-primary badge-pill">
                  Adquira já
                </span>
                <h2 className="mt-4">O melhor serviço para redes sociais!</h2>
                <p className="mb-4 lh-190">
                Nós fornecemos a melhor solução para sua página ou canal, quem compraria de uma pagina com poucos seguidores? Ou acompanharia uma micro pagina com poucas curtidas? Ninguém gosta de acompanhar canais com poucos inscritos não é mesmo? Nós vamos te ajudar com isso, chega de suplicar ajuda dos outros, de forma barata você vai alcançar o sucesso tão merecido nas redes sociais.
                </p>
                <ul className="list-unstyled">
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">Crie campanhas ainda melhores tendo maior publico.</p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                        Transmita a mensagem  da sua marca para mais pessoas
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Deixe sua marca brilhar e cresça em vendas
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Monetize através da suas Redes Socias
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                        Pagamento Seguro através do Mercado Pago
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <div className="about-content-right">
                  <img
                    src="img/delivery-app.svg"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
