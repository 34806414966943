module.exports = {
  hero: {
    title: "Brainstorming for desired perfect Usability",
    description:
      "Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!",
    bgImage: "img/app-hero-bg.jpg"
  },
  promo: {
    title: "Why small business owners love AppCo?",
    description:
      "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
    items: [
      {
        title: "Clean Design",
        description:
          "Increase sales by showing true dynamics of your website",
        icon: "ti-vector text-white"
      },
      {
        title: "Secure Data",
        description:
          "Build your online store’s trust using Social Proof & Urgency",
        icon: "ti-lock text-white"
      },
      {
        title: "Retina Ready",
        description:
          "Realize importance of social proof in customer’s purchase decision",
        icon: "ti-eye text-white"
      }
    ]
  },
  aboutUs: {
    title: {
      bold: "Use your android or ios device",
      normal: ""
    },
    description:
      "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
    items: [
      {
        title: "Clean Design",
        description:
          "Increase sales by showing true dynamics of your website",
        icon: "ti-vector text-white"
      },
      {
        title: "Secure Data",
        description:
          "Build your online store’s trust using Social Proof & Urgency",
        icon: "ti-lock text-white"
      },
      {
        title: "Retina Ready",
        description:
          "Realize importance of social proof in customer’s purchase decision",
        icon: "ti-eye text-white"
      }
    ]
  },
  price: {
    title: "Pricing Packages",
    description:
      "",
    packages: [
      {
        price: "R$ 49,90 por 1.000",
        license: "Instagram",
        isFeatured: false,
        features: [
          "Seguidores Reais Brasileiros",
          "Refil 30 dias",
          "Máximo de 10.000",
          "Até 48 horas",
          "Não requer Senha"
        ]
      },
      {
        price: "R$ 44,90 por 1.000",
        license: "Facebook",
        isFeatured: true,
        features: [
          "Seguidores Reais Brasileiros",
          "Refil 30 dias",
          "Máximo de 10.000",
          "Até 48 horas",
          "Não requer Senha"
        ]
      },
      {
        price: "R$ 51,23 por 1.000",
        license: "Twitter",
        isFeatured: false,
        features: [
          "Seguidores Estrangeiros",
          "Refil 30 dias",
          "Máximo de 10.000",
          "Até 48 horas",
          "Não requer Senha"
        ]
      }
    ]
  },
  testimonial: {
    title: "O que as pessoas dizem de nós",
    description:
      '',
    comments: [
      {
        name: "Emanuelle",
        company: "Engenheira",
        image: "img/client-2.jpg",
        comment:
          "Eu tinha vários projetos bacanas mas pouca audiência em minhas redes sociais. As pessoas que visitavam meu perfil, como tinham poucos seguidores, achavam que meu trabalho não era bom. Depois que ganhei muitos seguidores, tudo mudou para melhor !!!"
      },
      {
        name: "Lucas",
        company: "Tiktoker",
        image: "img/client-1.jpg",
        comment:
          "Nunca mais precisei pedir para me seguirem em grupos ou ficar seguindo as pessoas para ganhar meus próprios seguidores."
      },
      {
        name: "Benjamim",
        company: "Youtuber",
        image: "img/client-3.jpg",
        comment:
          "Depois de tentar comprar em vários sites que se diziam 'Confiáveis' eu finalmente achei o site de vocês Seguidor4u."
      },
      {
        name: "Miguel",
        company: "Designer",
        image: "img/client-4.jpg",
        comment:
        "Comprei inscritos para meu Youtube e é incrível a diferença no crescimento do canal, agora consigo demonstrar meus produtos e ideias."
      }
    ]
  },
  contact: {
    title: "Suporte",
    description:
      "O nosso suporte funciona 24 horas",

    phone: "+55-41-99942-1860",
    email: "seguidor4u@gmail.com"
  },
  trustedCompany: [
    "img/client-1-color.png",
    "img/client-6-color.png",
    "img/client-3-color.png",
    "img/client-4-color.png",
    "img/client-5-color.png"
  ],
  trustedCompanyGray: [
    "img/client-1-gray.png",
    "img/client-2-gray.png",
    "img/client-3-gray.png",
    "img/client-4-gray.png",
    "img/client-5-gray.png"
  ],
  trustedCompanyAboutUs: {
    title: "Trusted by companies",
    description: "Rapidiously morph transparent internal or \"organic\" sources whereas resource sucking e-business. Conveniently innovate compelling internal.",
    companies: [
      "img/client-5-color.png",
      "img/client-1-color.png",
      "img/client-6-color.png",
      "img/client-3-color.png",
      "img/client-4-color.png",
      "img/client-5-color.png",
      "img/client-7-color.png",
      "img/client-2-color.png",
      "img/client-1-color.png",
      "img/client-3-color.png"
    ]
  },
  teamMember: {
    members: [
      {
        name: "Edna Mason",
        designation: "Senior Designer",
        image: "img/team-4.jpg"
      },
      {
        name: "Arabella Ora",
        designation: "Senior Designer",
        image: "img/team-1.jpg"
      },
      {
        name: "John Charles",
        designation: "Senior Designer",
        image: "img/team-2.jpg"
      },
      {
        name: "Jeremy Jere",
        designation: "Senior Designer",
        image: "img/team-3.jpg"
      },
    ]
  },
  video: {
    link: "https://www.youtube.com/watch?v=9No-FiEInLA"
  }
};
