import React from "react";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer-section">
          <div
            className={"footer-top background-img-2 " + (this.props.noSubscription ? 'py-5' : 'pt-150 pb-5')} 
            style={{
              backgroundImage: "url('img/footer-bg.png')",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center top",
              backgroundSize: "cover"
            }}
          >
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-3 mb-3 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <img
                      src="img/logo-white-1x.png"
                      alt="footer logo"
                      width="80"
                      className="img-fluid mb-3"
                    />
                    <p>
                      Conheça as nossas Redes Sociais.
                    </p>

                    <div className="social-list-wrap">
                      <ul className="social-list list-inline list-unstyled">
                        <li className="list-inline-item">
                          <a href="https://www.facebook.com/seguidor4u/" target="_blank" title="Facebook">
                            <span className="ti-facebook"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="https://twitter.com/4useguidor?fbclid=IwAR2eFn8nwrH7v2q_IqJ93wz-tmEYesD77n9T2Lf5BS9QH2sS3WqnxrVnYEY" target="_blank" title="Twitter">
                            <span className="ti-twitter"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="https://www.instagram.com/seguidor4u/?fbclid=IwAR1GD6GANGNvDtlGZz3tMHZA_jmH4f2GpD7EOUtEvxQGEW-tGTjawoqg0og" target="_blank" title="Instagram">
                            <span className="ti-instagram"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="https://youtube.com/channel/UCdHsJL2pLYtrkd9KaJUJpZg" target="_blank" title="Youtube">
                            <span className="ti-youtube"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="https://vm.tiktok.com/ZM8j7Gqq2/" target="_blank" title="TikTok">
                            <span className="ti-github"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Suporte ou Dúvidas</h5>
                    <ul className="list-unstyled support-list">
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-mobile mr-2"></span>{" "}
                        <a href="tel:+5541999421860"> +55 41 99942-1860</a>
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-email mr-2"></span>
                        <a href="mailto:seguidor4u@gmail.com<"> seguidor4u@gmail.com</a>
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-world mr-2"></span>
                        <a href="/"> www.seguidor4u.com</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom gray-light-bg pt-4 pb-4">
            <div className="container">
              <div className="row text-center justify-content-center">
                <div className="col-md-6 col-lg-5">
                  <p className="copyright-text pb-0 mb-0">
                    Copyrights © 2021. Todos os direitos reservados 
                    <a href="/#"> Seguidor4u</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
